<template>
  <erp-box style="min-height: 216px" label="Assinatura para documentos">
    <div class="prestador-assinatura"
         style="width: 305px; height: 105px; background-color: #FFFFFF; margin: 20px auto auto; border: #333333 1px dotted; display: flex; align-items: center; justify-content: center">
      <a target="_blank" :href="assinatura ? assinatura.url : '#'"
         v-if="tmp || (assinatura)">
        <img id="frame" :src="tmp || assinatura.url" style="max-width: 300px; max-height: 100px">
      </a>
      <u-icon v-else name="image"/>
    </div>
    <p class="small text-center font-thin"><i>Resolução recomendada: 200x100px</i></p>
    <div class="text-center m-b-xs">
      <label for="camera">
        <e-btn @click="$refs.camera.click()" icon="upload" icon-type="fa" class="m-r-sm"/>
        <e-btn v-if="changed && !toDelete" @click="save" icon="save" icon-type="fa" class="m-r-sm bg-positive"/>
        <input @change="cameraListener($event)" type="file" accept="image/*" capture="camera" id="camera" ref="camera"
               v-show="false">
      </label>
      <e-btn @click.prevent="deleteImage" :disable="!assinatura && !tmp" icon="times" icon-type="fa"
             icon-color="negative"/>
    </div>
  </erp-box>
</template>

<script>
import {ErpBox} from 'uloc-vue-plugin-erp'
import {fileToBase64} from '@/utils/fileToBase64'
import {findAssinatura, uploadAssinatura, deleteAssinatura} from "@/domain/pessoa/domain"

export default {
  name: 'PrestadorAssinatura',
  props: ['pessoa'],
  data() {
    return {
      assinatura: null,
      changed: false,
      tmp: null,
      type: null,
      toDelete: false
    }
  },
  components: {
    ErpBox
  },
  mounted() {
    this.load()
  },
  beforeDestroy() {
  },
  methods: {
    reset () {
      this.assinatura = null
      this.changed = false
      this.tmp = null
      this.type = null
      this.toDelete = false
    },
    load() {
      this.reset()
      findAssinatura(this.pessoa.id, 'assinatura-imagem')
          .then(response => {
            console.log(response.data)
            this.assinatura = response.data
          })
          .catch(error => {
            console.error(error)
          })
    },
    cameraListener(e) {
      let frame = document.getElementById('frame')
      this.$file = e.target.files[0];
      // Do something with the image file.
      this.tmp = URL.createObjectURL(this.$file)
      this.changed = true
      this.toDelete = false
      this.type = 'upload'
      window.setTimeout(() => {
        this.updateImage()
      }, 50)
      // frame.src = URL.createObjectURL(file);
    },
    deleteImage() {
      this.$uloc.dialog({
        title:  'Deletar assinatura',
        message: 'Tem certeza que deseja deletar esta assinatura?',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        this.$uloc.loading.show()
        deleteAssinatura(this.pessoa.id, this.assinatura.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    getUploadedFileContent() {
      const file = this.$file
      return fileToBase64(file)
    },
    hasChanged() {
      return this.changed
    },
    getImage() {
      let response
      if (this.type === 'upload') {
        response = this.getUploadedFileContent()
      }
      return response
    },
    updateImage() {
      this.getImage()
          .then(file => {
            this.$filePrepared = file
            this.$emit('update', file)
          })
          .catch(error => {
            alert('Falha ao processar a assinatura, mas os demais dados foram processados com sucesso!')
          })
    },
    save() {
      this.$uloc.loading.show()
      let method = 'POST'
      let data = {data: this.$filePrepared, filename: 'assinatura', tipo: 'assinatura-imagem'}
      uploadAssinatura(this.pessoa.id, data, method)
          .then(response => {
            console.log(response.data)
            this.$uloc.loading.hide()
            this.load()
          })
          .catch(error => {
            this.alertApiError(error)
            this.$uloc.loading.hide()
          })
    }
  }
}
</script>
